import { createEvent, restore } from "effector";
import { useStore } from "effector-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ERoutes } from "shared/config/enums";

const resetRoute = createEvent();
const redirectTo = createEvent<ERoutes>();
const $route = restore(redirectTo, null).reset(resetRoute);

const useRouter = () => {
  const navigate = useNavigate();
  const route = useStore($route);

  useEffect(() => {
    if (!route) return;
    navigate(route);
    resetRoute();
  }, [route]);
};

export const routerModel = {
  useRouter,
  redirectTo,
};
