import { FC, PropsWithChildren } from 'react'

import { useTranslation } from 'react-i18next'
import { ReactComponent as Telegram } from 'shared/assets/svg/tg.svg'
import { footer, tgLink } from './style'

export const Footer: FC<PropsWithChildren> = () => {
  const { t } = useTranslation()
  return (
    <div className={footer}>
      <div>
        <a
          href={`https://telegram.me/${process.env.REACT_APP_TELEGRAM_CHANNEL}`}
          target="_blank"
          rel="noreferrer"
        >
          <Telegram />
        </a>
        <p>
          <>{t('Footer.support')}&nbsp;</>
          <a
            className={tgLink}
            href={`https://telegram.me/${process.env.REACT_APP_TELEGRAM_CHANNEL}`}
            target="_blank"
            rel="noreferrer"
          >
            Telegram
          </a>
        </p>
      </div>
    </div>
  )
}
