import { css } from "@emotion/css";

export const header = css`
  background: #ffffff;
  border-bottom: 1px solid var(--gray3);

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 85px;
    @media screen and (max-width: 425px) {
      height: 56px;
    }
  }

  padding: 0 12px;
`;

export const Logo = css`
  display: flex;
  align-items: center;
  font-weight: 800;
  font-size: 15px;
  color: var(--active);
  text-decoration: none;
  @media screen and (min-width: 425px) {
    font-size: 24px;
  }
`;
export const telegram = css`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  color: #0088cc;
  text-decoration: none;

  svg {
    margin-right: 13px;
    @media screen and (max-width: 768px) {
      width: 16px;
      margin-right: 11px;
    }
  }
`;
