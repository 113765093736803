import { FC, PropsWithChildren } from "react";
import { Logo, header, telegram } from "./style";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ReactComponent as Telegram } from "shared/assets/svg/tg.svg";
import { pageContainer } from "shared/css/global";

export const Header: FC<PropsWithChildren> = () => {
  const { t } = useTranslation();

  return (
    <div className={header}>
      <div className={pageContainer}>
        <Link to="/" className={Logo}>
          {process.env.REACT_APP_TITLE}
        </Link>
        <a
          href={`https://telegram.me/${process.env.REACT_APP_TELEGRAM_CHANNEL}`}
          className={telegram}
          target="_blank"
          rel="noreferrer"
        >
          <Telegram />
          <span>
            <>{t("Header.support")}</>
          </span>
        </a>
      </div>
    </div>
  );
};
