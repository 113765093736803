import { css } from "@emotion/css";
import pattern from "./img/pattern.png";

export const global = css`
  display: flex;
  flex-direction: column;

  /* cyrillic-ext */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(shared/assets/fonts/Montserrat/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2)
      format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
      U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(shared/assets/fonts/Montserrat/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2)
      format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* vietnamese */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(shared/assets/fonts/Montserrat/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2)
      format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169,
      U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(shared/assets/fonts/Montserrat/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2)
      format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
      U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(shared/assets/fonts/Montserrat/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2)
      format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(shared/assets/fonts/Montserrat/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2)
      format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
      U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(shared/assets/fonts/Montserrat/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2)
      format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* vietnamese */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(shared/assets/fonts/Montserrat/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2)
      format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169,
      U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(shared/assets/fonts/Montserrat/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2)
      format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
      U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(shared/assets/fonts/Montserrat/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2)
      format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(shared/assets/fonts/Montserrat/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2)
      format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
      U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(shared/assets/fonts/Montserrat/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2)
      format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* vietnamese */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(shared/assets/fonts/Montserrat/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2)
      format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169,
      U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(shared/assets/fonts/Montserrat/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2)
      format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
      U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(shared/assets/fonts/Montserrat/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2)
      format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(shared/assets/fonts/Montserrat/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2)
      format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
      U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(shared/assets/fonts/Montserrat/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2)
      format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* vietnamese */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(shared/assets/fonts/Montserrat/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2)
      format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169,
      U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(shared/assets/fonts/Montserrat/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2)
      format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
      U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(shared/assets/fonts/Montserrat/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2)
      format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }
  /* cyrillic-ext */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(shared/assets/fonts/Montserrat/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr73w0aXp-p7K4KLjztg.woff2)
      format("woff2");
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
      U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(shared/assets/fonts/Montserrat/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr73w9aXp-p7K4KLjztg.woff2)
      format("woff2");
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* vietnamese */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(shared/assets/fonts/Montserrat/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr73w2aXp-p7K4KLjztg.woff2)
      format("woff2");
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169,
      U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr73w3aXp-p7K4KLjztg.woff2)
      format("woff2");
    unicode-range: U+0100-02AF, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF,
      U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr73w5aXp-p7K4KLg.woff2)
      format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
      U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
      U+2215, U+FEFF, U+FFFD;
  }

  * {
    font-family: "Montserrat", sans-serif;
  }

  background: url(${pattern});
  min-height: 100%;
`;
