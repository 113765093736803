import React, { lazy, Suspense } from "react";

import { Route, Routes } from "react-router-dom";
import { routerModel } from "shared/hooks/useRouter";

const routes = [
  { name: "Exchange", path: "/" },
  { name: "Order", path: "/order" },
];

const routeElements = routes.map((route) => {
  const Element = lazy(async () => {
    return import(`pages/${route.name}`).then((modules) => ({
      default: modules[route.name],
    }));
  });

  return (
    <Route
      key={route.name}
      path={route.path}
      element={
        <Suspense>
          <Element />
        </Suspense>
      }
    />
  );
});

export const Routing: React.FC = () => {
  routerModel.useRouter();
  return <Routes>{routeElements}</Routes>;
};
