import i18next, { i18n, TFunction } from 'i18next'
import { initReactI18next } from 'react-i18next'
import RU from './locales/ru-RU.json'

const resources = {
  en: {
    translation: RU,
  },
}

i18next.use(initReactI18next).init({
  lng: 'en',
  interpolation: {
    escapeValue: false,
  },
  resources,
})
export type TLocalize = typeof RU
export interface Customi18n extends i18n {
  t: TFunction
}

export default i18next as Customi18n
