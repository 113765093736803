import { css } from '@emotion/css'

export const footer = css`
  padding: 30px 0;
  width: 100%;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  margin-top: 100px;

  @media screen and (max-width: 768px) {
    margin-top: 80px;
  }
  @media screen and (max-width: 425px) {
    white-space: break-spaces;
    padding: 31px 0 24px;
    div {
      flex-direction: column;
      margin: 0 auto;
      text-align: center;
    }
    p {
      line-height: 24px;
    }
    svg {
      margin-bottom: 13px;
    }
  }

  p {
    margin: 0;
  }

  @media screen and (min-height: 944px) {
    margin-top: auto;
  }

  background: var(--gray3);

  font-weight: 500;
  font-size: 16px;
  color: var(--black);

  svg {
    margin-right: 13px;
  }
`

export const tgLink = css`
  color: var(--active);
  text-decoration: none;
`
