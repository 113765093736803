import { FC } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import "normalize.css";
import "shared/css/reset.css";
import "shared/css/variables.css";
import "shared/locales/";

import { Routing } from "pages";
import { Footer } from "widgets/Footer";
import { Header } from "widgets/Header";
import { global } from "./style";

const App: FC = () => {
  return (
    <div className={global}>
      <Router>
        <Header />
        <Routing />
        <Footer />
      </Router>
    </div>
  );
};

export default App;
